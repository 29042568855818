import { Amplify } from "aws-amplify";

export const configAmplify = (region) => {
  localStorage.setItem("region", region);
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: window.config[`AWS_REGION_${region}`],
      userPoolId: window.config[`COGNITO_USER_POOL_ID_${region}`],
      userPoolWebClientId: window.config[`COGNITO_APP_CLIENT_ID_${region}`],
      oauth: {
        domain: window.config[`COGNITO_USER_POOL_DOMAIN_${region}`],
        scope: [
          "phone",
          "email",
          "profile",
          "openid",
          "aws.cognito.signin.user.admin",
        ],
        redirectSignIn: window.config[`COGNITO_REDIRECT_SIGNIN_${region}`],
        redirectSignOut: window.config[`COGNITO_REDIRECT_SIGNOUT_${region}`],
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
      federationTarget: "COGNITO_USER_POOLS",
    },
    Storage: {
      region: window.config[`AWS_REGION_${region}`],
      bucket: window.config[`S3_BUCKET_${region}`],
    },
    API: {
      endpoints: [
        {
          name: "freelancer-portal",
          endpoint: window.config[`API_URL_${region}`],
          region: window.config[`AWS_REGION_${region}`],
        },
      ],
    },
  });
};
