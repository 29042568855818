import React, { Component } from "react";
import { Auth } from "aws-amplify";
import $ from "jquery";
import LoaderButton from "../../components/LoaderButton";
import CountryCodeList from "../../components/CountryCodeList";
import { withRouter } from "react-router-dom";
import { parsePhoneNumber } from "libphonenumber-js";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      activitylog: [],
      newsfeeds: [],
      isLoading: true,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      errormessage: "",

      SuccessModalText: "You have changed password successfully",
      UserData: false,
      SignatureImage: "",
      FirstName: "",
      LastName: "",
      EmailAddress: "",
      MobileNumber: "",
      CountryCode: "",
      MinutsStep: 5,
      TimeOption: "DropList",
      LastLogin: "",
      Password: "",
      Avatar: "images/profile_pic.png",

      selectedFileValidate: true,
      selectedFile: null,
      AvatarPreview: null,

      phoneValid: "",
      FirstNameValid: true,
      LastNameValid: true,
      MobileNumberValid: true,
      formValid: false,
    };
  }

  async componentDidMount() {
    try {
      const user = await this.getUserDetail();
      const authUser = await Auth.currentAuthenticatedUser();
      //console.log("user detail=>", user);

      if (user.status) {
        localStorage.setItem("UserGuid", user.result.Guid);
        localStorage.setItem("UserFirstName", user.result.FirstName);
        localStorage.setItem("UserLastName", user.result.LastName);
        localStorage.setItem("UserEmailAddress", user.result.EmailAddress);
        localStorage.setItem(
          "UserMinutsStep",
          user.result.MinutsStep ? user.result.MinutsStep : 5
        );
        localStorage.setItem(
          "UserTimeOption",
          user.result.TimeOption ? user.result.TimeOption : "DropList"
        );

        const parsedPhoneNumber = parsePhoneNumber(
          authUser.attributes["phone_number"]
        );
        const CountryCode = parsedPhoneNumber.countryCallingCode;
        const MobileNumber = authUser.attributes["phone_number"]
          ?.split(CountryCode)
          .pop();

        this.setState(
          {
            UserData: { ...user.result, MobileNumber, CountryCode },
            FirstName: user.result.FirstName,
            LastName: user.result.LastName,
            EmailAddress: user.result.EmailAddress,
            CountryCode: CountryCode,
            MobileNumber: MobileNumber,
            MinutsStep: user.result.MinutsStep,
            TimeOption: user.result.TimeOption,
            LastLogin: user.result.LastLogin,
            Password: null,
          },
          async () => {
            const response = await this.props.postRequest(
              "/attachments/get-profile-pic",
              {}
            );
            if (response.status) {
              this.setState({ Avatar: response.profile_pic });
            }
            console.log("/attachments/get-profile-pic=>", response);

            if (this.props.location.search?.includes("edit-profile=true")) {
              document.getElementById("edit_profile_btn")?.click();
            }

            const log = await this.props.getRequest(`/activity-log/list`);
            if (log.status) {
              this.setState({ activitylog: log.result });
            }

            const feeds = await this.props.getRequest(`/news-feed/list`);

            if (feeds.status) {
              this.setState({ newsfeeds: feeds.result });
            }
            this.setState({ isLoading: false });
            await this.props.postRequest("/settings/initialize", {});
          }
        );
      } else {
        await Auth.signOut();
        window.location.href = "/";
      }
    } catch (e) {
      console.log("Home component error: " + e);
    }

    //this.showModal();
    //this.setState({SuccessModalText: "Oooops"}, this.showModal);
  }

  async sortByProperty(property) {
    return function (x, y) {
      return x[property] === y[property]
        ? 0
        : x[property] > y[property]
        ? 1
        : -1;
    };
  }

  async showModal() {
    $("#successModalBtn").click();
  }

  handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "FirstName" || name === "LastName") {
      const ValidStateName = name + "Valid";
      this.setState({ [ValidStateName]: true });
      this.setState({ [name]: value }, () => {
        this.validateField(name, value, ValidStateName);
      });
    } else if (name === "MinutsStep") {
      const parsedValue = parseInt(value);
      if (isNaN(parsedValue)) {
        this.setState({ [name]: "" });
      } else if (value > 0 && value <= 30) {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  validatePhone() {
    var countrycode = this.state.CountryCode;
    let phoneN = "+" + countrycode + this.state.MobileNumber;
    if (phoneN.match(/^\+\d+$/)) {
      this.setState({ MobileNumberValid: true });
      return true;
    } else {
      this.setState({ MobileNumberValid: false });
      return false;
    }
  }

  validateField(fieldName, value, ValidStateName) {
    let valid = true;

    if (value.length === 0) {
      valid = false;
    }

    this.setState({ [ValidStateName]: valid }, this.validateForm);
  }

  validateForm() {
    var formValid =
      this.state.FirstNameValid &&
      this.state.LastNameValid &&
      this.state.MobileNumberValid;
    this.setState({ formValid: formValid });
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  fileChangedHandler = async (event) => {
    const file = event.target.files[0];
    const mimeType = file.type;
    console.log("mimeType=>", mimeType);
    if (
      mimeType !== "image/jpeg" &&
      mimeType !== "image/jpg" &&
      mimeType !== "image/png"
    ) {
      alert("Only jpg/jpeg and png files are allowed!");
      return false;
    }

    try {
      const base64Data = await this.toBase64(file);
      //console.log("base64Data=>", base64Data);
      this.setState({
        selectedFile: base64Data,
        AvatarPreview: base64Data,
      });
    } catch (err) {
      alert(err.message);
    }
  };

  uploadBtnHandler() {
    $("#fileUploadBtn").click();
  }

  handleUpdate = async (event) => {
    await this.validateForm();
    if (this.state.formValid) {
      let formattedPhoneNumber = this.state.MobileNumber;
      if (!formattedPhoneNumber.includes("+")) {
        formattedPhoneNumber = `+${this.state.CountryCode}${formattedPhoneNumber}`;
      }

      this.setState({ isLoading: true });
      let user = await Auth.currentAuthenticatedUser();
      if (user.username === null) {
        this.handleUpdateUserData(formattedPhoneNumber);
      } else {
        let result = await Auth.updateUserAttributes(user, {
          phone_number: formattedPhoneNumber,
          "custom:firstname": this.state.FirstName,
          "custom:lastname": this.state.LastName,
        })
          .then(async (result) => {
            this.handleUpdateUserData(formattedPhoneNumber, true);
          })
          .catch((err) => {
            console.log("user update error=>", err);
            this.setState({ isLoading: false });
            alert(err.message);
          });
      }
    }
  };

  handleUpdateUserData = async (
    formattedPhoneNumber,
    checkAuthUserValidity = false
  ) => {
    try {
      if (this.state.selectedFile) {
        const attachment = await this.props.postRequest(
          "/attachments/upload-profile-pic",
          { base64Data: this.state.selectedFile }
        );
        console.log("/attachments/upload-profile-pic=>", attachment);
        if (attachment.status) {
          this.setState({ Avatar: this.state.selectedFile }, () => {
            this.setState({
              selectedFile: null,
              AvatarPreview: null,
            });
          });
        } else {
          this.setState({ isLoading: false });
          alert(attachment.error);
        }
      }
      const response = await this.props.postRequest(`/users/update`, {
        FirstName: this.state.FirstName,
        LastName: this.state.LastName,
        MinutsStep: this.state.MinutsStep,
        TimeOption: this.state.TimeOption,
        MobileNumber: formattedPhoneNumber,
      });
      if (response.status) {
        let UserData = this.state.UserData;
        UserData.FirstName = this.state.FirstName;
        UserData.LastName = this.state.LastName;

        localStorage.setItem("UserFirstName", this.state.FirstName);
        localStorage.setItem("UserLastName", this.state.LastName);
        localStorage.setItem("UserMinutsStep", this.state.MinutsStep);
        localStorage.setItem("UserTimeOption", this.state.TimeOption);

        $("#profileModalClose").click();
        this.saveActivity();
        this.setState(
          { SuccessModalText: "Successfully Updated" },
          this.showModal
        );
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
        alert(response.error);
      }
    } catch (e) {
      this.setState({ isLoading: false });
      alert(e.message);
    } finally {
      if (checkAuthUserValidity) {
        const updatedUser = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });
        if (!updatedUser.attributes.phone_number_verified) {
          // phone number has been updated, redirect to phone number verify page
          this.props.history.push("/verify");
        }
      }
    }
  };

  handleCancel = (event) => {
    this.setState({
      FirstName: this.state.UserData.FirstName,
      LastName: this.state.UserData.LastName,
      EmailAddress: this.state.UserData.EmailAddress,
      MobileNumber: this.state.UserData.MobileNumber,
      selectedFile: null,
      AvatarPreview: null,
    });
  };

  saveActivity = async (event) => {
    const item = {
      Description: "User Profile data has been edit",
      IPAddress: "192.11.98.9",
      Type: "PROFILE UPDATED",
    };
    try {
      const response = await this.createActivitylog(item);
      console.log("Add Activity Response:" + response);
    } catch (e) {
      console.log("Add Activity Error:" + e);
    }
  };

  validatePhone() {
    let phoneN = "+" + this.state.CountryCode + this.state.MobileNumber;
    if (phoneN.match(/^\+\d+$/)) {
      this.setState({ phoneValid: "" });
      return true;
    } else {
      this.setState({ phoneValid: "Phone number is invalid" });
      return false;
    }
  }

  getUserDetail() {
    return this.props.getRequest(`/users/get-user-detail`);
  }

  createActivitylog(item) {
    return this.props.postRequest(`/activity-log/create`, item);
  }

  validateChangeForm() {
    return (
      this.state.oldPassword.length > 0 &&
      this.state.newPassword.length > 0 &&
      this.state.confirmPassword.length > 0
    );
  }

  handleChangePassword = async (event) => {
    event.preventDefault();

    if (this.state.oldPassword === "") {
      this.setState({ errormessage: "Old password is required." });
      return false;
    }

    if (this.state.newPassword === "") {
      this.setState({ errormessage: "New password is required." });
      return false;
    }

    if (this.state.confirmPassword === "") {
      this.setState({ errormessage: "Confirm password is required." });
      return false;
    }

    if (this.state.confirmPassword !== this.state.newPassword) {
      this.setState({ errormessage: "Confirm passwords do not match." });
      return false;
    }

    this.setState({ isLoading: true, errormessage: "" });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          this.state.oldPassword,
          this.state.confirmPassword
        );
      })
      .then((data) => {
        $("#changePasswordModalClose").click();
        this.setState(
          {
            isLoading: false,
            errormessage: "",
            SuccessModalText: "You have changed password successfully",
          },
          this.showModal
        );
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.code === "NotAuthorizedException") {
          this.setState({ errormessage: "Incorrect old password." });
        } else if (err.code === "InvalidPasswordException") {
          this.setState({
            errormessage:
              "password must be at least 8 characters long contain a number, an uppercase letter and a symbol.",
          });
        } else {
          alert(err.message);
          this.setState({ isLoading: false, errormessage: err.message });
        }
      });
  };

  renderLog(activitylog) {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return activitylog.map((log, i) => (
      <div className="feed-item" key={i}>
        <div className="date">
          {months[new Date(log.TTL).getMonth()]} {new Date(log.TTL).getDate()},{" "}
          {new Date(log.TTL).getFullYear()}
        </div>
        <div className="text">
          {log.Type}
          <br />
          {log.Description}
        </div>
      </div>
    ));
  }

  renderFeeds(feeds) {
    var index = 0;
    var length = feeds.length;
    let slide = [];
    for (var i = index; i < length; i = i + 3) {
      let children = [];
      children.push(
        <div className="col-sm-6 col-md-4 news_feed_carsoul">
          {feeds[i] ? this.renderFeed(feeds[i]) : ""}
        </div>
      );
      children.push(
        <div className="col-sm-6 col-md-4 news_feed_carsoul">
          {feeds[i + 1] ? this.renderFeed(feeds[i + 1]) : ""}
        </div>
      );
      children.push(
        <div className="col-sm-6 col-md-4 news_feed_carsoul slider_diplay_none">
          {feeds[i + 2] ? this.renderFeed(feeds[i + 2]) : ""}
        </div>
      );

      slide.push(
        <div className={i === 0 ? "item active" : "item"} key={i}>
          {children}
        </div>
      );
    }
    return slide;
  }

  renderFeedsMobile(feeds) {
    var index = 0;
    var length = feeds.length;
    let slide = [];
    for (var i = index; i < length; i++) {
      let children = [];
      children.push(
        <div className="col-xs-12 news_feed_carsoul">
          {feeds[i] ? this.renderFeed(feeds[i]) : ""}
        </div>
      );

      slide.push(
        <div className={i === 0 ? "item active" : "item"} key={i}>
          {children}
        </div>
      );
    }
    return slide;
  }

  renderFeed(feed) {
    return (
      <div className="col-item">
        <div className="photo">
          <img src={feed.Image} className="img-responsive" alt="a" />
        </div>

        <h2> {feed.Headline} </h2>
        <div className="clearfix"></div>
        <p>{feed.Desc}</p>

        <div className="clearfix dsd"></div>
      </div>
    );
  }

  handleImageError = async (event) => {
    this.setState({ Avatar: "images/profile_pic.png" });
  };

  render() {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return (
      <div className="col-xs-12 col-sm-9 col-md-10 pull-right mrg_dashboard_right">
        {this.state.isLoading ? <div className="is-loading"></div> : ""}
        <div className="dahboard_hdg">Dashboard</div>
        <div className="clearfix"></div>

        <div className="col-sm-12 col-md-9">
          <div className="clear20 sm_display_none"></div>
          <div className="col-sm-12 col-md-9 p0">
            <div className="welcome_box">
              <div className="col-xs-4 col-sm-4 p0">
                <div className="col-sm-12 p0 text-center">
                  {" "}
                  <img
                    src={this.state.Avatar}
                    className="profile_img"
                    onError={this.handleImageError}
                    alt=""
                  />
                </div>
                <div className="clear20"></div>
                <a
                  href="#"
                  id="edit_profile_btn"
                  className="btn_edit_profile"
                  data-toggle="modal"
                  data-target="#profileModal"
                >
                  Edit Profile
                </a>
              </div>

              <div className="col-xs-8 col-sm-8 p0 label_weclome_admin">
                <h2>
                  {" "}
                  Welcome, {this.state.FirstName} {this.state.LastName}
                </h2>{" "}
                Last Login:{" "}
                {this.state.LastLogin
                  ? months[new Date(this.state.LastLogin).getMonth()] +
                    " " +
                    new Date(this.state.LastLogin).getDate() +
                    ", " +
                    new Date(this.state.LastLogin).getFullYear()
                  : ""}
              </div>
              <div className="clearfix"></div>
            </div>
          </div>

          <div className="col-sm-12 col-md-3 p0_sm_Res">
            <div
              className="timecard_box"
              style={{ cursor: "pointer" }}
              onClick={(event) => this.props.addTimeCard(event)}
            >
              <img
                src="images/home/ic_receipt_24px.svg"
                width="43"
                height="48"
                alt=""
              />
              <div className="clear10"></div>
              Insert TimeCard
            </div>
          </div>
          <div className="clear20"></div>

          <div className="col-sm-12 p0">
            <div className="news_heading_bg">News Feed</div>

            <div className="news_feedbox">
              <div
                className="controls hidden-xs col-sm-12"
                style={{
                  display: this.state.newsfeeds.length === 0 ? "none" : "block",
                }}
              >
                <a
                  className="left news_feed_arrow1"
                  href="#carousel-exam"
                  data-slide="prev"
                >
                  <img
                    src="images/home/ic_chevron_left_24px.svg"
                    width="15"
                    height="23"
                    alt=""
                  />
                </a>
                <a
                  className="right news_feed_arrow2"
                  href="#carousel-exam"
                  data-slide="next"
                >
                  <img
                    src="images/home/ic_chevron_right_24px.svg"
                    width="15"
                    height="23"
                    alt=""
                  />
                </a>

                <div
                  id="carousel-exam"
                  className="carousel slide hidden-xs"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    {this.state.newsfeeds.length === 0
                      ? ""
                      : this.renderFeeds(this.state.newsfeeds)}
                  </div>
                </div>
              </div>

              <div className="controls full_hidden_slider_feed col-sm-12">
                <a
                  className="left news_feed_arrow1"
                  href="#carousel-example"
                  data-slide="prev"
                >
                  <img
                    src="images/home/ic_chevron_left_24px.svg"
                    width="15"
                    height="23"
                    alt=""
                  />
                </a>
                <a
                  className="right news_feed_arrow2"
                  href="#carousel-example"
                  data-slide="next"
                >
                  <img
                    src="images/home/ic_chevron_right_24px.svg"
                    width="15"
                    height="23"
                    alt=""
                  />
                </a>

                <div
                  id="carousel-example"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    {this.state.newsfeeds.length === 0
                      ? ""
                      : this.renderFeedsMobile(this.state.newsfeeds)}
                  </div>
                </div>
              </div>

              <div className="clearfix"></div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-offset-0 col-md-3 p0 recent_label">
          <div className="activity_feed_sm">
            <h2 className="recent_h2">Activity Feed</h2>
            <div className="activity-feed">
              {this.state.activitylog.length === 0
                ? ""
                : this.renderLog(this.state.activitylog)}
            </div>
          </div>
        </div>
        {/* Profile Modal Start. */}
        <div className="clear40"></div>
        <div
          className="modal fade  modal_res_forg"
          id="profileModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="profileModalTitle"
          aria-hidden="true"
          style={{ display: "none" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modla_edit_profile"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header modal_header_register">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.handleCancel}
                  id="profileModalClose"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body register_suc register_popup">
                <div className="user_edting_hd">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="1585 5909 17.515 18.002"
                  >
                    <path
                      id="ic_create_24px"
                      className="cls-1"
                      d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                      transform="translate(1582 5906.002)"
                    ></path>
                  </svg>
                  User Setting
                </div>

                <div className="clear20"></div>
                <div className="col-sm-4 p0">
                  <div className="user_setting_box">
                    <div className="col-sm-12 p0 text-center">
                      {" "}
                      <img
                        src={
                          this.state.AvatarPreview
                            ? this.state.AvatarPreview
                            : this.state.Avatar
                        }
                        className="profile_img"
                        alt=""
                        onError={this.handleImageError}
                      />
                    </div>
                    <div className="clear20"></div>
                    <button
                      type="button"
                      className="btn_changephoto"
                      onClick={this.uploadBtnHandler}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-9284 -445 18 16.2"
                      >
                        <g
                          id="ic_photo_camera_24px"
                          transform="translate(-9286 -447)"
                        >
                          <path
                            id="Path_97"
                            data-name="Path 97"
                            className="cls-1"
                            d="M2.88,0A2.88,2.88,0,1,1,0,2.88,2.88,2.88,0,0,1,2.88,0Z"
                            transform="translate(8.12 8.12)"
                          ></path>
                          <path
                            id="Path_92"
                            data-name="Path 92"
                            className="cls-1"
                            d="M8.3,2,6.653,3.8H3.8A1.805,1.805,0,0,0,2,5.6V16.4a1.805,1.805,0,0,0,1.8,1.8H18.2A1.805,1.805,0,0,0,20,16.4V5.6a1.805,1.805,0,0,0-1.8-1.8H15.347L13.7,2ZM11,15.5A4.5,4.5,0,1,1,15.5,11,4.5,4.5,0,0,1,11,15.5Z"
                          ></path>
                        </g>
                      </svg>
                      <span>Change photo</span>
                    </button>
                  </div>

                  <div className="clear40"></div>
                  <div className="btn_cance_save">
                    <input
                      name=""
                      type="button"
                      className="btn_save_pro"
                      value={!this.state.isLoading ? "Save" : "Seving..."}
                      onClick={this.handleUpdate}
                      disabled={this.state.isLoading}
                    />
                    <input
                      name=""
                      type="button"
                      className="btn_cancel_pro"
                      value="Cancel"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.handleCancel}
                      disabled={this.state.isLoading}
                    />
                  </div>
                </div>

                <div className="col-sm-8 profile_setting_pop">
                  <form className="form-horizontal">
                    <div
                      className={
                        !this.state.FirstNameValid
                          ? "form-group field_required"
                          : "form-group"
                      }
                    >
                      <label
                        className="control-label col-sm-4"
                        htmlFor="First Name"
                      >
                        First Name
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="First Name"
                          className="form-control pro_input_pop"
                          name="FirstName"
                          value={this.state.FirstName}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        !this.state.LastNameValid
                          ? "form-group field_required"
                          : "form-group"
                      }
                    >
                      <label
                        className="control-label col-sm-4"
                        htmlFor="Last Name"
                      >
                        Last Name
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="Last Name"
                          className="form-control pro_input_pop"
                          name="LastName"
                          value={this.state.LastName}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label col-sm-4" htmlFor="Email">
                        Email
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="Email"
                          className="form-control pro_input_pop"
                          placeholder="example@gmail.com"
                          name="EmailAddress"
                          value={this.state.EmailAddress}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        htmlFor="Mobile"
                      >
                        Mobile
                      </label>
                      <div className="col-sm-8">
                        <div className="col-sm-12 p0">
                          <div className="col-sm-6 p0">
                            <select
                              className="form-control pro_input_pop"
                              name="CountryCode"
                              value={this.state.CountryCode}
                              onChange={this.handleChange}
                            >
                              <CountryCodeList />
                            </select>
                          </div>
                          <div className="col-sm-6 p0">
                            <input
                              type="Mobile"
                              className="form-control pro_input_pop"
                              placeholder="123456789"
                              name="MobileNumber"
                              value={this.state.MobileNumber}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        htmlFor="Mobile"
                      >
                        Minutes Step
                      </label>
                      <div className="col-sm-8">
                        <div className="col-sm-12 p0">
                          <input
                            type="number"
                            className="form-control pro_input_pop"
                            placeholder="00"
                            name="MinutsStep"
                            value={this.state.MinutsStep}
                            onChange={this.handleChange}
                            min="1"
                            max="30"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        htmlFor="Mobile"
                      >
                        Time Option
                      </label>
                      <div className="col-sm-8">
                        <div className="col-sm-12 p0">
                          <select
                            className="form-control pro_input_pop"
                            name="TimeOption"
                            value={this.state.TimeOption}
                            onChange={this.handleChange}
                          >
                            <option value="DropList">Drop List</option>
                            <option value="Type">Type</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="clear20"></div>

                    <div className="col-sm-8">
                      {localStorage.getItem("SocialLoggedin") === "yes" ? (
                        ""
                      ) : (
                        <button
                          type="button"
                          href="#"
                          className="btn_change_pass_pro"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="-9022 -414 13 17.063"
                          >
                            <path
                              id="ic_lock_24px"
                              className="cls-1"
                              d="M15.375,6.688h-.812V5.063a4.063,4.063,0,0,0-8.125,0V6.688H5.625A1.63,1.63,0,0,0,4,8.313v8.125a1.63,1.63,0,0,0,1.625,1.625h9.75A1.63,1.63,0,0,0,17,16.438V8.313A1.63,1.63,0,0,0,15.375,6.688ZM10.5,14a1.625,1.625,0,1,1,1.625-1.625A1.63,1.63,0,0,1,10.5,14Zm2.519-7.312H7.981V5.063a2.519,2.519,0,0,1,5.038,0Z"
                              transform="translate(-9026 -415)"
                            ></path>
                          </svg>
                          <span
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#changePasswordModal"
                          >
                            Change Password
                          </span>
                        </button>
                      )}
                    </div>
                  </form>

                  <div className="btn_cance_save2">
                    <input
                      name=""
                      type="button"
                      className="btn_save_pro"
                      value={!this.state.isLoading ? "Save" : "Seving..."}
                      onClick={this.handleUpdate}
                      disabled={this.state.isLoading}
                    />
                    <input
                      name=""
                      type="button"
                      className="btn_cancel_pro"
                      value="Cancel"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.handleCancel}
                      disabled={this.state.isLoading}
                    />
                  </div>
                </div>

                <div className="clear10"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade  modal_res_forg"
          id="changePasswordModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="changePasswordModalTitle"
          aria-hidden="true"
          style={{ display: "none" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modla_edit_profile"
            role="document"
            style={{ marginTop: "10px" }}
          >
            <div className="modal-content forgotPasswordModalContent">
              <div className="modal-header modal_header_register">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="changePasswordModalClose"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body register_suc register_popup forgotPasswordPopupBody">
                <div className="forgot_box forgotPasswordBoxContainer">
                  <div className="clear10"></div>
                  <div className="forgot_circle">
                    <img src="images/ic_lock1_outline_24px.svg" alt="" />
                  </div>
                  <h2> Change Password?</h2>
                  <div className="clear10"></div>

                  <div className="clear10"></div>
                  <div className="register_box_mid register_box_mid2">
                    {!this.state.errormessage ? (
                      ""
                    ) : (
                      <div>
                        <div className="clear20"></div>
                        <div className="alert alert-danger text-uppercase">
                          {this.state.errormessage}
                        </div>
                      </div>
                    )}
                    <form onSubmit={this.handleChangePassword}>
                      <div className="col-sm-12 p0">
                        <input
                          name="oldPassword"
                          id="oldPassword"
                          className="frogot_input forgotPasswordInput"
                          type="password"
                          placeholder="Old Password"
                          value={this.state.oldPassword}
                          onChange={this.handleChange}
                        />
                        <img
                          src="images/ic_vpn_key_24px.svg"
                          width="15px"
                          height="15px"
                          className="register_icon1 forgotPasswordEmailIcon"
                          alt=""
                        />
                      </div>
                      <div className="clear10"></div>
                      <div className="col-sm-12 p0">
                        <input
                          name="newPassword"
                          id="newPassword"
                          className="frogot_input forgotPasswordInput"
                          type="password"
                          placeholder="New Password"
                          value={this.state.newPassword}
                          onChange={this.handleChange}
                        />
                        <img
                          src="images/ic_vpn_key_24px.svg"
                          width="15px"
                          height="15px"
                          className="register_icon1 forgotPasswordEmailIcon"
                          alt=""
                        />
                      </div>
                      <div className="clear10"></div>
                      <div className="col-sm-12 p0">
                        <input
                          name="confirmPassword"
                          id="confirmPassword"
                          className="frogot_input forgotPasswordInput"
                          type="password"
                          placeholder="Confirm Password"
                          value={this.state.confirmPassword}
                          onChange={this.handleChange}
                        />
                        <img
                          src="images/ic_vpn_key_24px.svg"
                          width="15px"
                          height="15px"
                          className="register_icon1 forgotPasswordEmailIcon"
                          alt=""
                        />
                      </div>
                      <div className="clear30"></div>
                      <LoaderButton
                        block
                        bsSize="small"
                        disabled={!this.validateChangeForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Proceed"
                        loadingText="Sending…"
                        className="btn_forogot btn_forogot_new"
                      />
                    </form>
                    <div className="clear10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade modal_res_forg"
          id="changePasswordSuccessModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modla_register"
            role="document"
            style={{ marginTop: "10px" }}
          >
            <div className="modal-content">
              <div className="modal-header modal_header_register">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body register_suc register_popup">
                <img
                  src="images/ic_check_circle_24px.svg"
                  width={47}
                  height={47}
                  alt=""
                />
                <div className="clearfix" />
                <h2> {this.state.SuccessModalText}</h2>
                <div className="clearfix" />
                <button
                  className="btn_forogot btn_forogot_new"
                  data-dismiss="modal"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Profile Model End. */}
        <button
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#changePasswordSuccessModal"
          id="successModalBtn"
          style={{ display: "none" }}
        >
          Show Modal
        </button>
        <input
          type="file"
          onChange={this.fileChangedHandler}
          id="fileUploadBtn"
          style={{ display: "none" }}
          accept="image/*"
        />
      </div>
    );
  }
}

export default withRouter(Dashboard);
