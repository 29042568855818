import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import CountryCode from "../components/CountryCode";
import SignaturePad from "../components/SignaturePad/index";

export default class Register extends Component {
  constructor(props) {
    super();
    this.file = null;
    this.state = {
      isLoading: true,
      isSaving: false,
      SignatureImage: "",
      SignatureText: "",
      SignatureTyped: "",
      DisplayPad: "block",
      SignatureInput: "block",
      CurrentTab: "Draw",
      CurrentTabMobile: "DrawMobile",
      SignaturePad: "",
    };
  }

  async componentDidMount() {
    /*try {
      var response = await this.props.postRequest(
        "/attachments/get-signature",
        {}
      );
      if (response.status) {
        this.setState({
          isLoading: false,
          SignatureImage: response.result.signature,
          DisplayPad: "none",
          SignatureTyped: response.result.typed_signature,
          SignatureInput: "none",
        });
      }
      console.log("/attachments/get-signature=>", response);
    } catch (err) {
      console.log("/attachments/get-signature error=>", err);
    }*/
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  clearSignature = async (event) => {
    if (this.state.CurrentTab === "Draw") {
      this.setState({ SignatureImage: "", DisplayPad: "block" });
      const signature = this.refs.Signature;
      signature.clear();

      //document.getElementsByTagName("canvas")[0].width = 'auto';
      //document.getElementsByTagName("canvas")[0].height = 'auto';
    }

    if (this.state.CurrentTab === "Typed") {
      this.setState({
        SignatureTyped: "",
        SignatureText: "",
        SignatureInput: "block",
      });
    }
  };

  saveSignature = async (event) => {
    if (this.state.DisplayPad === "none" && this.state.CurrentTab === "Draw") {
      return false;
    }
    if (
      this.state.SignatureInput === "none" &&
      this.state.CurrentTab === "Typed"
    ) {
      return false;
    }
    this.setState({ isSaving: true });
    if (this.state.CurrentTab === "Draw") {
      const signature = this.refs.Signature;

      if (signature.isEmpty()) {
        console.log("remove signatur");
        try {
          var rem_response = await this.props.postRequest(
            "/attachments/upload-signature",
            {}
          );
          console.log("rem_response", rem_response);
          if (rem_response.status) {
            const user = await this.updateLogin();
            this.setState({
              isSaving: false,
              SignatureImage: "",
              DisplayPad: "block",
            });
            window.location.href = "/dashboard";
          } else {
            this.setState({ isSaving: false });
            console.log("upload signature rem_response=>", rem_response);
          }
        } catch (err) {
          this.setState({ isSaving: false });
          console.log("upload signature rem_response Error=>", err);
        }
      } else {
        const base64 = signature.toDataURL("image/jpeg");
        //console.log(base64); return false;
        try {
          var response = await this.props.postRequest(
            "/attachments/upload-signature",
            { base64Data: base64 }
          );
          console.log("rem_response>", response);
          if (response.status) {
            console.log("/attachments/upload-signature=>", response);

            const user = await this.updateLogin();
            this.setState({
              isSaving: false,
              SignatureImage: base64,
              DisplayPad: "none",
            });
            window.location.href = "/dashboard";
          } else {
            this.setState({ isSaving: false });
            console.log("upload signature response=>", response);
          }
        } catch (err) {
          this.setState({ isSaving: false });
          console.log("upload signature Error=>", err);
        }
      }
    }

    if (this.state.CurrentTab === "Typed") {
      //debugger;
      let SignatureText = this.state.SignatureText;
      if (SignatureText) {
        try {
          var img_response = await this.props.postRequest("/text-to-image", {
            SignatureText: SignatureText,
          });
          if (img_response.status) {
            const base64Image = img_response.base64Image;
            console.log("base64Image=>", base64Image);
            var typed_response = await this.props.postRequest(
              "/attachments/upload-signature",
              { base64Data: base64Image, Typed: true }
            );
            if (typed_response.status) {
              const user = await this.updateLogin();
              this.setState({
                isSaving: false,
                SignatureTyped: base64Image,
                SignatureInput: "none",
              });
              window.location.href = "/dashboard";
            } else {
              this.setState({ isSaving: false });
              console.log("upload typed signature response=>", response);
            }
          } else {
            this.setState({ isSaving: false });
            console.log("html to image response=>", typed_response);
          }
        } catch (err) {
          this.setState({ isSaving: false });
          console.log("upload signature Error=>", err);
        }
      } else {
        console.log("remove typed signatur");
        var rem_response = await this.props.postRequest(
          "/attachments/upload-signature",
          { Typed: true }
        );
        if (rem_response.status) {
          const user = await this.updateLogin();
          this.setState({
            isSaving: false,
            SignatureTyped: "",
            SignatureInput: "block",
          });
          window.location.href = "/dashboard";
        } else {
          this.setState({ isSaving: false });
          console.log("upload signature rem_response=>", rem_response);
        }
      }
    }
  };

  clearSignatureMobile = async (event) => {
    if (this.state.CurrentTabMobile === "DrawMobile") {
      const signatureMobile = this.refs.SignatureMobile;
      signatureMobile.clear();
      this.setState({ SignatureImage: "", DisplayPad: "block" });
    }

    if (this.state.CurrentTabMobile === "TypedMobile") {
      this.setState({
        SignatureTyped: "",
        SignatureText: "",
        SignatureInput: "block",
      });
    }
  };

  saveSignatureMobile = async (event) => {
    if (
      this.state.DisplayPad === "none" &&
      this.state.CurrentTabMobile === "DrawMobile"
    ) {
      return false;
    }

    if (
      this.state.SignatureInput === "none" &&
      this.state.CurrentTabMobile === "TypedMobile"
    ) {
      return false;
    }

    this.setState({ isSaving: true });
    if (this.state.CurrentTabMobile === "DrawMobile") {
      const signatureMobile = this.refs.SignatureMobile;
      if (signatureMobile.isEmpty()) {
        console.log("remove signatur");
        try {
          var rem_response = await this.props.postRequest(
            "/attachments/upload-signature",
            {}
          );
          if (rem_response.status) {
            const user = await this.updateLogin();
            this.setState({
              isSaving: false,
              SignatureImage: "",
              DisplayPad: "block",
            });
            window.location.href = "/dashboard";
          } else {
            this.setState({ isSaving: false });
            console.log("upload signature rem_response=>", rem_response);
          }
        } catch (err) {
          this.setState({ isSaving: false });
          console.log("upload signature rem_response Error=>", err);
        }
      } else {
        const base64 = signatureMobile.toDataURL("image/jpeg");
        //console.log(base64); return false;
        try {
          var response = await this.props.postRequest(
            "/attachments/upload-signature",
            { base64Data: base64 }
          );
          if (response.status) {
            console.log("/attachments/upload-signature=>", response);

            const user = await this.updateLogin();
            this.setState({
              isSaving: false,
              SignatureImage: base64,
              DisplayPad: "none",
            });
            window.location.href = "/dashboard";
          } else {
            this.setState({ isSaving: false });
            console.log("upload signature response=>", response);
          }
        } catch (err) {
          this.setState({ isSaving: false });
          console.log("upload signature Error=>", err);
        }
      }
    }

    if (this.state.CurrentTabMobile === "TypedMobile") {
      let SignatureText = this.state.SignatureText;
      if (SignatureText) {
        try {
          var img_response = await this.props.postRequest("/text-to-image", {
            SignatureText: SignatureText,
          });
          if (img_response.status) {
            const base64Image = img_response.base64Image;
            console.log("base64Image=>", base64Image);
            var typed_response = await this.props.postRequest(
              "/attachments/upload-signature",
              { base64Data: base64Image, Typed: true }
            );

            if (typed_response.status) {
              const user = await this.updateLogin();
              this.setState({
                isSaving: false,
                SignatureTyped: base64Image,
                SignatureInput: "none",
              });
              window.location.href = "/dashboard";
            } else {
              this.setState({ isSaving: false });
              console.log("upload typed signature response=>", response);
            }
          } else {
            this.setState({ isSaving: false });
            console.log("html to image response=>", typed_response);
          }
        } catch (err) {
          this.setState({ isSaving: false });
          console.log("upload signature Error=>", err);
        }
      } else {
        console.log("remove typed signatur");
        var rem_response = await this.props.postRequest(
          "/attachments/upload-signature",
          { Typed: true }
        );
        if (rem_response.status) {
          const user = await this.updateLogin();
          this.setState({
            isSaving: false,
            SignatureTyped: "",
            SignatureInput: "block",
          });
          window.location.href = "/dashboard";
        } else {
          this.setState({ isSaving: false });
          console.log("upload signature rem_response=>", rem_response);
        }
      }
    }
  };

  updateLogin() {
    let email = this.props.location.state;
    console.log("email in history=>", email);
    return this.props.postRequest("/users/update-login", {
      userEmail: email.toLowerCase(),
    });
  }

  handleTab = (element) => async (event) => {
    event.preventDefault();
    this.setState({ CurrentTab: element });
  };

  handleTabMobile = (element) => async (event) => {
    event.preventDefault();
    this.setState({ CurrentTabMobile: element });
  };

  handleImageError = async (event) => {
    const signature = this.refs.Signature;
    signature.clear();

    const SignatureMobile = this.refs.SignatureMobile;
    SignatureMobile.clear();

    this.setState({ SignatureImage: "", DisplayPad: "block" });
    localStorage.setItem("Signature", "");
  };

  handleImageTypeError = async (event) => {
    this.setState({
      SignatureTyped: "",
      SignatureText: "",
      SignatureInput: "block",
    });
    //this.setState({ SignatureTyped: "", SignatureInput: "block" });
  };

  handleLogout = async (event) => {
    localStorage.removeItem("loggedin");
    await Auth.signOut();
    //this.userHasAuthenticated(false);
    this.props.history.push("/");
  };

  render() {
    return (
      <div className="col-sm-12 register_bg">
        <div className="container p0">
          <div className="register_box w-650">
            <h2> Signature </h2>
            <a href="javascript:void" onClick={this.handleLogout}>
              <img
                src="images/ic_chevron_left1_24px.svg"
                className="back_signup"
                width="15"
                height="23"
                alt=""
              />
            </a>
            <hr />
            <div className="register_box_mid">
              <div className="clearfix"></div>
              {/* Signature Tabs  */}
              <div
                className="col-xs-12 col-sm-9 col-md-10 pull-right mrg_dashboard_right"
                style={{ width: "100%", margin: "0px", padding: "0px" }}
              >
                <div
                  className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 time_table_mrg"
                  style={{ width: "100%", margin: "0px" }}
                >
                  <div className="clear20"></div>

                  <div
                    className="col-xs-12 col-sm-10 mrg_left_sign"
                    style={{ width: "100%", margin: "0px" }}
                  >
                    <div className="tabbable-panel">
                      <div className="tabbable-line">
                        <ul className="nav nav-tabs ">
                          <li className="active">
                            <a
                              href="#tab_default_1"
                              data-toggle="tab"
                              onClick={this.handleTab("Draw")}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="-3800 7611 15 15"
                              >
                                <path
                                  id="ic_mode_edit_24px"
                                  className="cls-1"
                                  d="M3,14.873V18H6.125L15.34,8.782,12.215,5.658ZM17.756,6.366a.83.83,0,0,0,0-1.175l-1.95-1.95a.83.83,0,0,0-1.175,0L13.107,4.766l3.125,3.125Z"
                                  transform="translate(-3803 7608.002)"
                                />
                              </svg>
                              &nbsp;&nbsp; Drawn
                            </a>
                          </li>
                          <li>
                            <a
                              href="#tab_default_2"
                              data-toggle="tab"
                              onClick={this.handleTab("Typed")}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="-2112 7612 20 14"
                              >
                                <path
                                  id="ic_keyboard_24px"
                                  className="cls-1"
                                  d="M20,5H4A2,2,0,0,0,2.01,7L2,17a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20,5ZM11,8h2v2H11Zm0,3h2v2H11ZM8,8h2v2H8Zm0,3h2v2H8ZM7,13H5V11H7Zm0-3H5V8H7Zm9,7H8V15h8Zm0-4H14V11h2Zm0-3H14V8h2Zm3,3H17V11h2Zm0-3H17V8h2Z"
                                  transform="translate(-2114 7607)"
                                />
                              </svg>
                              &nbsp;&nbsp; Typed
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            className="tab-pane active"
                            id="tab_default_1"
                            style={{ padding: "5px" }}
                          >
                            <p></p>
                            <SignaturePad
                              SignaturePad={this.state}
                              ref="Signature"
                            />
                            <img
                              className="img-responsive"
                              src={this.state.SignatureImage}
                              alt=""
                              onError={this.handleImageError}
                            />
                          </div>
                          <div
                            className="tab-pane"
                            id="tab_default_2"
                            style={{ color: "#aaaaaa" }}
                          >
                            <p></p>
                            <img
                              src={this.state.SignatureTyped}
                              onError={this.handleImageTypeError}
                            />
                            <p style={{ display: this.state.SignatureInput }}>
                              <input
                                name="SignatureText"
                                id="SignatureText"
                                className="form-control pro_input_pop"
                                value={this.state.SignatureText}
                                onChange={this.handleChange}
                                type="text"
                                placeholder="Type Here"
                                defaultValue=""
                              />
                            </p>
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clear40"></div>
                    <div className="btn_cance_save">
                      <input
                        name=""
                        className="btn_save_pro"
                        value={!this.state.isSaving ? "Save" : "Saving.."}
                        disabled={this.state.isSaving}
                        type="button"
                        onClick={this.saveSignature}
                      />
                      <input
                        name=""
                        className="btn_cancel_pro"
                        value="Clear"
                        type="button"
                        onClick={this.clearSignature}
                      />
                    </div>
                  </div>
                </div>
                <div className="clear40"></div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 time_table_mrg_res p0">
                <div className="clear5"></div>
                <div className="col-xs-12 p0">
                  <div className="clear40"></div>
                  <div className="tabbable-panel">
                    <div className="tabbable-line">
                      <ul className="nav nav-tabs ">
                        <li className="active">
                          <a
                            href="#tab_default_3"
                            data-toggle="tab"
                            onClick={this.handleTabMobile("DrawMobile")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="-3800 7611 15 15"
                            >
                              <path
                                id="ic_mode_edit_24px"
                                className="cls-1"
                                d="M3,14.873V18H6.125L15.34,8.782,12.215,5.658ZM17.756,6.366a.83.83,0,0,0,0-1.175l-1.95-1.95a.83.83,0,0,0-1.175,0L13.107,4.766l3.125,3.125Z"
                                transform="translate(-3803 7608.002)"
                              />
                            </svg>
                            &nbsp;&nbsp; Drawn
                          </a>
                        </li>
                        <li>
                          <a
                            href="#tab_default_4"
                            data-toggle="tab"
                            onClick={this.handleTabMobile("TypedMobile")}
                            style={{ color: "#aaaaaa" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="-2112 7612 20 14"
                            >
                              <path
                                id="ic_keyboard_24px"
                                className="cls-1"
                                d="M20,5H4A2,2,0,0,0,2.01,7L2,17a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20,5ZM11,8h2v2H11Zm0,3h2v2H11ZM8,8h2v2H8Zm0,3h2v2H8ZM7,13H5V11H7Zm0-3H5V8H7Zm9,7H8V15h8Zm0-4H14V11h2Zm0-3H14V8h2Zm3,3H17V11h2Zm0-3H17V8h2Z"
                                transform="translate(-2114 7607)"
                              />
                            </svg>
                            &nbsp;&nbsp; Typed
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="clear40"></div>
                  <div className="col-xs-12">
                    <div className="tab-content res_tab_box">
                      <div className="tab-pane active" id="tab_default_3">
                        <p></p>
                        <SignaturePad
                          SignaturePad={this.state}
                          ref="SignatureMobile"
                        />
                        <img
                          className="img-responsive"
                          src={this.state.SignatureImage}
                          alt=""
                          onError={this.handleImageError}
                        />
                      </div>
                      <div
                        className="tab-pane"
                        id="tab_default_4"
                        style={{ color: "#aaaaaa" }}
                      >
                        <p></p>
                        <div
                          style={{
                            display:
                              this.state.SignatureInput === "block"
                                ? "none"
                                : "inline-block",
                          }}
                        >
                          <img
                            className="img-responsive"
                            src={this.state.SignatureTyped}
                            onError={this.handleImageTypeError}
                          />
                        </div>
                        <p style={{ display: this.state.SignatureInput }}>
                          <input
                            name="SignatureText"
                            id="SignatureText"
                            className="form-control pro_input_pop"
                            value={this.state.SignatureText}
                            onChange={this.handleChange}
                            type="text"
                            placeholder="Type Here"
                            defaultValue=""
                          />
                        </p>
                        <p></p>
                      </div>
                    </div>
                  </div>

                  <div className="clear20"></div>
                  <div className="col-sm-12">
                    <input
                      name=""
                      className="btn_submit_time"
                      value={!this.state.isSaving ? "Save" : "Saving.."}
                      disabled={this.state.isSaving}
                      type="button"
                      onClick={this.saveSignatureMobile}
                    />
                    <input
                      name=""
                      className="btn_cancel_pro"
                      value="Clear"
                      type="button"
                      onClick={this.clearSignatureMobile}
                    />
                  </div>
                  <div className="clear20"></div>
                </div>
                <div className="clear40"></div>
              </div>
              {/* mobile view end. */}

              <canvas
                id="myCanvas"
                height="40"
                style={{ border: "1px solid #d3d3d3", display: "none" }}
              >
                <img
                  id="image"
                  alt="Signature File"
                  style={{ display: "none" }}
                />
              </canvas>

              {/* --------------- */}
              <div className="col-sm-12 p0 text-center"></div>
              <div className="clear30"></div>
            </div>
          </div>
          <div className="clear40"></div>
        </div>
      </div>
    );
  }
}
