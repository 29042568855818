import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import App from "./App";
//import * as serviceWorker from './serviceWorker';
import "./index.css";
import { configAmplify } from "./utils/configUtils";

const region = localStorage.getItem("region");
if (region && (region === "AU" || region === "UK")) {
  configAmplify(region);
  console.log("dummy");
}

// Sentry.init({dsn: "https://28e09296517547f5b3d0ecc8e301740c@sentry.io/1795748"});
Sentry.init({
  dsn: window.config.SENTRY_DSN,
  attachStacktrace: true,
  normalizeDepth: 0,

  // Filter out debug, info and log level events
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/
  beforeSend(event) {
    const eventLevel = event.level;
    if (eventLevel === 'debug' || eventLevel === 'info' || eventLevel === 'log') {
      return null;
    }
    return event;
  },

  // Capture Replay for 10% of all sessions,
  replaysSessionSampleRate: 0.1,
  // plus for 100% of sessions with an error
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.Replay()
  ]
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register();
