import React , { Component } from "react";
import SignaturePad from "../../components/SignaturePad/index";
import $ from 'jquery';
//import { s3Upload } from "../../libs/awsLib";
import axios from 'axios';

export default class SignatureSettings extends Component {

	constructor(props)
	{
		super();
		this.file = null;
        this.state = {
          isLoading: true,
      	  isSaving: false,
          SignatureImage: "",
          SignatureText: "",
          SignatureTyped: "",
          DisplayPad: "block",
          SignatureInput: "block",
          CurrentTab: "Draw",
          CurrentTabMobile: "DrawMobile",
          SignaturePad: ""
        };
	}

	async componentDidMount() {
		
		
		try{
          var response = await this.props.postRequest("/attachments/get-signature", {});
          if(response.status){
            this.setState({ 
            	isLoading: false,
            	SignatureImage: response.result.signature,
            	DisplayPad: "none",
            	SignatureTyped: response.result.typed_signature,
            	SignatureInput: "none"
            })
          }
          console.log("/attachments/get-signature=>", response);
        }catch(err){
          console.log("/attachments/get-signature error=>", err);
        }
	}

	handleChange = event => {
		this.setState(
		{
			[event.target.id]: event.target.value
		});
	}

	
	clearSignature = async event => {
		if(this.state.CurrentTab === "Draw"){
			this.setState({ SignatureImage: "", DisplayPad: "block" });
			const signature = this.refs.Signature;
			signature.clear();

			//document.getElementsByTagName("canvas")[0].width = 'auto';
			//document.getElementsByTagName("canvas")[0].height = 'auto';
		}

		if(this.state.CurrentTab === "Typed"){
			this.setState({ SignatureTyped: "", SignatureInput: "block" });
		}
	}

	saveSignature = async event => {
		if(this.state.DisplayPad === 'none' && this.state.CurrentTab === "Draw"){
			$('#HomeBtn').click();
			return false;
		}
		if(this.state.SignatureInput === 'none' && this.state.CurrentTab === "Typed"){
			$('#HomeBtn').click();
			return false;
		}
		this.setState({isSaving: true});
		if(this.state.CurrentTab === "Draw"){
			const signature = this.refs.Signature;
		    
		    if(signature.isEmpty()){
		    	console.log("remove signatur");
		    	try{
			        var rem_response = await this.props.postRequest("/attachments/upload-signature", {});
			        if(rem_response.status){
			        	this.setState({ isSaving: false, SignatureImage: "", DisplayPad: "block" });
			        }else{
			        	this.setState({ isSaving: false });
			    		console.log("upload signature rem_response=>", rem_response);
			        }
			        
			    }catch(err){
			        this.setState({ isSaving: false });
			        console.log("upload signature rem_response Error=>", err);
			    }
		    }else{
				const base64 = signature.toDataURL("image/jpeg");
				//console.log(base64); return false;
				try{
			        var response = await this.props.postRequest("/attachments/upload-signature", {base64Data: base64});
			        if(response.status){
			        	console.log("/attachments/upload-signature=>", response);
			        	$('#HomeBtn').click();
			        	this.setState({ isSaving: false, SignatureImage: base64, DisplayPad: "none" });
			        }else{
			        	this.setState({ isSaving: false });
			    		console.log("upload signature response=>", response);
			        }
			        
			    }catch(err){
			        this.setState({ isSaving: false });
			        console.log("upload signature Error=>", err);
			    }								
  			}
		}

		if(this.state.CurrentTab === "Typed"){
			let SignatureText = this.state.SignatureText;
			if(SignatureText){
				try{
				    var img_response = await this.props.postRequest("/text-to-image", { SignatureText: SignatureText});
				    if(img_response.status){
				    	const base64Image = img_response.base64Image;
				    	console.log("base64Image=>", base64Image);
				    	var typed_response = await this.props.postRequest("/attachments/upload-signature", {base64Data: base64Image, Typed: true});
				        if(typed_response.status){
				        	this.setState({ isSaving: false, SignatureTyped: base64Image, SignatureInput: "none" });
				        }else{
				        	this.setState({ isSaving: false });
				    		console.log("upload typed signature response=>", response);
				        }
				    }else{
				    	this.setState({ isSaving: false });
				    	console.log("html to image response=>", typed_response);
				    }
				}catch(err){
				    this.setState({ isSaving: false });
				    console.log("upload signature Error=>", err);
				}
			}else{
				console.log("remove typed signatur");
				var rem_response = await this.props.postRequest("/attachments/upload-signature", {Typed: true});
			    if(rem_response.status){
			        this.setState({ isSaving: false, SignatureTyped: "", SignatureInput: "block" });
			    }else{
			        this.setState({ isSaving: false });
			    	console.log("upload signature rem_response=>", rem_response);
			    }
			}
		}
	}

	clearSignatureMobile = async event => {

		if(this.state.CurrentTabMobile === "DrawMobile"){
			const signatureMobile = this.refs.SignatureMobile;
			signatureMobile.clear();
			this.setState({ SignatureImage: "", DisplayPad: "block" });
		}

		if(this.state.CurrentTabMobile === "TypedMobile"){
			this.setState({ SignatureTyped: "", SignatureInput: "block" });
		}
	}

	saveSignatureMobile = async event => {
		if(this.state.DisplayPad === 'none' && this.state.CurrentTabMobile === "DrawMobile"){
			$('#HomeBtn').click();
			return false;
		}

		if(this.state.SignatureInput === 'none' && this.state.CurrentTabMobile === "TypedMobile"){
			$('#HomeBtn').click();
			return false;
		}

		this.setState({isSaving: true});
		if(this.state.CurrentTabMobile === "DrawMobile"){
			const signatureMobile = this.refs.SignatureMobile;
			if(signatureMobile.isEmpty()){
		    	console.log("remove signatur");
		    	try{
			        var rem_response = await this.props.postRequest("/attachments/upload-signature", {});
			        if(rem_response.status){
			        	this.setState({ isSaving: false, SignatureImage: "", DisplayPad: "block" });
			        }else{
			        	this.setState({ isSaving: false });
			    		console.log("upload signature rem_response=>", rem_response);
			        }
			        
			    }catch(err){
			        this.setState({ isSaving: false });
			        console.log("upload signature rem_response Error=>", err);
			    }
		    }else{
				const base64 = signatureMobile.toDataURL("image/jpeg");
				//console.log(base64); return false;
				try{
			        var response = await this.props.postRequest("/attachments/upload-signature", {base64Data: base64});
			        if(response.status){
			        	console.log("/attachments/upload-signature=>", response);
			        	$('#HomeBtn').click();
			        	this.setState({ isSaving: false, SignatureImage: base64, DisplayPad: "none" });
			        }else{
			        	this.setState({ isSaving: false });
			    		console.log("upload signature response=>", response);
			        }
			        
			    }catch(err){
			        this.setState({ isSaving: false });
			        console.log("upload signature Error=>", err);
			    }								
  			}
		}

		if(this.state.CurrentTabMobile === "TypedMobile"){
			let SignatureText = this.state.SignatureText;
			if(SignatureText){
				try{
				    var img_response = await this.props.postRequest("/text-to-image", { SignatureText: SignatureText});
				    if(img_response.status){
				    	const base64Image = img_response.base64Image;
				    	console.log("base64Image=>", base64Image);
				    	var typed_response = await this.props.postRequest("/attachments/upload-signature", {base64Data: base64Image, Typed: true});
				        if(typed_response.status){
				        	this.setState({ isSaving: false, SignatureTyped: base64Image, SignatureInput: "none" });
				        }else{
				        	this.setState({ isSaving: false });
				    		console.log("upload typed signature response=>", response);
				        }
				    }else{
				    	this.setState({ isSaving: false });
				    	console.log("html to image response=>", typed_response);
				    }
				}catch(err){
				    this.setState({ isSaving: false });
				    console.log("upload signature Error=>", err);
				}
			}else{
				console.log("remove typed signatur");
				var rem_response = await this.props.postRequest("/attachments/upload-signature", {Typed: true});
			    if(rem_response.status){
			        this.setState({ isSaving: false, SignatureTyped: "", SignatureInput: "block" });
			    }else{
			        this.setState({ isSaving: false });
			    	console.log("upload signature rem_response=>", rem_response);
			    }
			}
		}

	}

	handleTab = (element) => async event => {
		event.preventDefault();
		this.setState({ CurrentTab: element });
	}

	handleTabMobile = (element) => async event => {
		event.preventDefault();
		this.setState({ CurrentTabMobile: element });
	}

	handleImageError = async event => {
		
		const signature = this.refs.Signature;
		signature.clear();

		const SignatureMobile = this.refs.SignatureMobile;
		SignatureMobile.clear();

		this.setState({ SignatureImage: "", DisplayPad: "block" });
		localStorage.setItem('Signature', "");
	}

	handleImageTypeError = async event => {
		this.setState({ SignatureTyped: "",SignatureText:"", SignatureInput: "block" });
		//this.setState({ SignatureTyped: "", SignatureInput: "block" });
	}


    render()
    {
        return (
            <div>
            <div className="col-xs-12 col-sm-9 col-md-10 pull-right mrg_dashboard_right" style={{width:'75%'}}>
                
                <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 time_table_mrg">
      <div className="clear40"></div>
        <div className="heading_1 svg_sign5 setting_icon5">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10048 7722.143 16 16">
  
  <path id="ic_edit_24px" className="cls-1 " d="M3,15.665V19H6.333l9.83-9.83L12.83,5.835ZM18.74,6.59a.885.885,0,0,0,0-1.253l-2.08-2.08a.885.885,0,0,0-1.253,0L13.781,4.884l3.333,3.333L18.74,6.59Z" transform="translate(-10051 7719.146)"/>
</svg>

         &nbsp; Signature Setting</div>
             <div className="clear20"></div>
             
             <div className="col-xs-12 col-sm-10 mrg_left_sign">
             	<div className="tabbable-panel">
				<div className="tabbable-line">
					<ul className="nav nav-tabs ">
						<li className="active">
							<a href="#tab_default_1" data-toggle="tab" onClick={this.handleTab('Draw')}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="-3800 7611 15 15">
  									<path id="ic_mode_edit_24px" className="cls-1" d="M3,14.873V18H6.125L15.34,8.782,12.215,5.658ZM17.756,6.366a.83.83,0,0,0,0-1.175l-1.95-1.95a.83.83,0,0,0-1.175,0L13.107,4.766l3.125,3.125Z" transform="translate(-3803 7608.002)"/>
								</svg>&nbsp;&nbsp; Drawn 
							</a>
						</li>
						<li>
							<a href="#tab_default_2" data-toggle="tab" onClick={this.handleTab('Typed')}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="-2112 7612 20 14">
  									<path id="ic_keyboard_24px" className="cls-1" d="M20,5H4A2,2,0,0,0,2.01,7L2,17a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20,5ZM11,8h2v2H11Zm0,3h2v2H11ZM8,8h2v2H8Zm0,3h2v2H8ZM7,13H5V11H7Zm0-3H5V8H7Zm9,7H8V15h8Zm0-4H14V11h2Zm0-3H14V8h2Zm3,3H17V11h2Zm0-3H17V8h2Z" transform="translate(-2114 7607)"/>
								</svg>&nbsp;&nbsp; Typed 
							</a>

						</li>
						
					</ul>
					<div className="tab-content">
						<div className="tab-pane active" id="tab_default_1" style={{padding: '5px'}}>
							<p></p>
						  	<SignaturePad SignaturePad={this.state} ref="Signature" />
							<img className="img-responsive" src={this.state.SignatureImage} alt="" onError={this.handleImageError} />
							
					  	</div>
						<div className="tab-pane" id="tab_default_2" style={{color: '#aaaaaa'}}>
							<p></p>
							<div style={{display:this.state.SignatureInput==="block"? "none" : "inline-block"}}>
								<img className="img-responsive" src={this.state.SignatureTyped} onError={this.handleImageTypeError} />
							</div>
							<p style={{display:this.state.SignatureInput}}>
								<input name="SignatureText" id="SignatureText" className="form-control pro_input_pop" value={this.state.SignatureText}  onChange={this.handleChange} type="text" placeholder="Type Here"  defaultValue="" />
							</p>
							<p></p>
						</div>
				  	</div>
				</div>
                
         
			</div>
            <div className="clear40"></div>
            <div className="btn_cance_save">
       			<input name="" className="btn_save_pro" value={!this.state.isSaving ? "Save" : "Saving.."} disabled={this.state.isSaving} type="button" onClick={this.saveSignature} />
       			<input name="" className="btn_cancel_pro" value="Clear" type="button" onClick={this.clearSignature} />
       		</div>
       		
<input value="Home" type="button" id="HomeBtn" style={{display:'none'}} onClick={this.props.handleView('Home','List')} />
            </div>
            </div>
          	<div className="clear40"></div>
    </div>
{/* mobile view Start. */}            
<div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 time_table_mrg_res p0">
    <div className="res_top_timecard">
        <div className="col-xs-2 chev_res_let">
            <a href="/dashboard">
             		<svg xmlns="http://www.w3.org/2000/svg" viewBox="2398 1881 13 19.418">
               		<path id="ic_chevron_left_24px" className="cls-1" d="M21,8.282,18.526,6,8,15.709l10.526,9.709L21,23.136l-8.035-7.427Z" transform="translate(2390 1875)"></path>
             	</svg>
            </a>
        </div>
        <div className="col-xs-8 text-center">Signature Setting</div>
        <div className="clearfix"></div>
	</div>
    <div className="clear5"></div>
    
    <div className="col-xs-12 p0">
      	<div className="clear40"></div>
  		<div className="tabbable-panel">
			<div className="tabbable-line">
				<ul className="nav nav-tabs ">
					<li className="active">
						<a href="#tab_default_3" data-toggle="tab" onClick={this.handleTabMobile('DrawMobile')}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="-3800 7611 15 15">
  								<path id="ic_mode_edit_24px" className="cls-1" d="M3,14.873V18H6.125L15.34,8.782,12.215,5.658ZM17.756,6.366a.83.83,0,0,0,0-1.175l-1.95-1.95a.83.83,0,0,0-1.175,0L13.107,4.766l3.125,3.125Z" transform="translate(-3803 7608.002)"/>
							</svg>&nbsp;&nbsp; Drawn
						</a>
					</li>
					<li>
						<a href="#tab_default_4" data-toggle="tab" onClick={this.handleTabMobile('TypedMobile')} style={{color: '#aaaaaa'}}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="-2112 7612 20 14">
  								<path id="ic_keyboard_24px" className="cls-1" d="M20,5H4A2,2,0,0,0,2.01,7L2,17a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20,5ZM11,8h2v2H11Zm0,3h2v2H11ZM8,8h2v2H8Zm0,3h2v2H8ZM7,13H5V11H7Zm0-3H5V8H7Zm9,7H8V15h8Zm0-4H14V11h2Zm0-3H14V8h2Zm3,3H17V11h2Zm0-3H17V8h2Z" transform="translate(-2114 7607)"/>
							</svg>&nbsp;&nbsp; Typed
						</a>
					</li>
						
				</ul>	
			</div>
        </div>
        <div className="clear40"></div>
        <div className="col-xs-12">
            <div className="tab-content res_tab_box">
				<div className="tab-pane active" id="tab_default_3">
					<p></p>
					<SignaturePad SignaturePad={this.state} ref="SignatureMobile" />
					<img className="img-responsive" src={this.state.SignatureImage} alt=""  onError={this.handleImageError} />
					
			  	</div>
				<div className="tab-pane" id="tab_default_4" style={{color: '#aaaaaa'}}>
					<p></p>
					<div style={{display:this.state.SignatureInput==="block"? "none" : "inline-block"}}>
						<img className="img-responsive" src={this.state.SignatureTyped} onError={this.handleImageTypeError} />
					</div>
					<p style={{display:this.state.SignatureInput}}>
						<input name="SignatureText" id="SignatureText" className="form-control pro_input_pop" value={this.state.SignatureText}  onChange={this.handleChange} type="text" placeholder="Type Here"  defaultValue="" />
					</p>
					<p></p>
				</div>	
			</div>
        </div>
            
        <div className="clear20"></div>
        <div className="col-sm-12">
      		<input name="" className="btn_submit_time" value={!this.state.isSaving ? "Save" : "Saving.."} disabled={this.state.isSaving} type="button"  onClick={this.saveSignatureMobile}/>
       		<input name="" className="btn_cancel_pro" value="Clear" type="button"  onClick={this.clearSignatureMobile}/>
       	</div>
        <div className="clear20"></div>
	</div>
   	<div className="clear40"></div>
          
</div>
{/* mobile view end. */}  

<canvas id="myCanvas" height="40" style={{border:'1px solid #d3d3d3', display:'none'}}>
	<img id='image' alt="Signature File" style={{display:'none'}} />
</canvas>         
            </div>
        );
    }
}