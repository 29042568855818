import React, { Component } from "react";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import CountryCode from "../components/CountryCode";
import { configAmplify } from "../utils/configUtils";

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null,
      emailValid: "",
      phoneValid: "",
      confirmPasswordValid: "",
      passwordValid: "",
      errormessage: "",
      isLoading: false,
      CountryCode: "",
      isVerifyingMobile: false,
      isInitializing: true,
      showPassword: false,
      showConfirmPassword: false,
      region: "-1",
    };
  }

  async componentDidMount() {
    try {
      if (this.props.location.pathname === "/verify") {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        if (!user.attributes.phone_number_verified) {
          // need to verify phone number
          this.setState(
            { newUser: user, isVerifyingMobile: true, isInitializing: false },
            () => {
              if (this.props.location.search?.includes("resend")) {
                this.handleResendPhoneVerificationCode();
              }
            }
          );
        } else {
          // already verified
          this.props.history.replace("/dashboard");
        }
      } else {
        this.setState({ isInitializing: false });
      }
    } catch (e) {
      this.props.history.push({ pathname: "/register", state: {} });
    }
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.mobile.length > 0 &&
      this.state.password === this.state.confirmPassword &&
      this.state.region !== "-1"
    );
  }

  validatePassword() {
    if (
      this.state.password.match(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?\"!@#%&/,><':;|_~`^\\]\\[\\)\\(]).{8,}"
      )
    ) {
      this.setState({ passwordValid: "" });
      if (this.state.password === this.state.confirmPassword) {
        this.setState({ confirmPasswordValid: "" });
        return true;
      } else {
        this.setState({
          confirmPasswordValid: "Confirm password must match the password",
        });
        return false;
      }
    } else {
      this.setState({
        passwordValid:
          'Password must contain eight characters, at least one capital letter, one number, and a special character from this set: ^ $ * [ ] { } ( ) ? - " ! @ # % & /  | : ; _ . ',
      });
      return false;
    }
  }

  validateEmail() {
    if (this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      this.setState({ emailValid: "" });
      return true;
    } else {
      this.setState({
        emailValid: "Email address is invalid. Please re-check.",
      });
      return false;
    }
  }

  validatePhone() {
    var countrycode = document.getElementById("phoneDropDown").value;
    let phoneN =
      "+" + document.getElementById("phoneDropDown").value + this.state.mobile;
    if (phoneN.match(/^\+\d+$/)) {
      this.setState({ phoneValid: "", CountryCode: countrycode });
      return true;
    } else {
      this.setState({ phoneValid: "Phone number is invalid" });
      return false;
    }
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  /**
   * Formats the phone number and removes the leading zero
   */
  getFormattedPhoneNumber() {
    let number = this.state.mobile;
    if (number.startsWith("0")) {
      number = this.state.mobile.substring(1);
    }
    return number;
  }

  handleChange = (event) => {
    if (event.target.id === "mobile") {
      if (event.target.value.match(/^\d+$/)) {
        this.setState({
          [event.target.id]: event.target.value,
        });
      } else if (event.target.value == 0) {
        this.setState({
          [event.target.id]: event.target.value,
        });
      } else {
        console.log("false condition");
      }
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let validatePassword = this.validatePassword();
    let validateEmail = this.validateEmail();
    let validatePhone = this.validatePhone();

    if (this.state.region === "-1") {
      this.setState({ errormessage: "Please select your region" });
      return;
    }
    if (validatePassword && validateEmail && validatePhone) {
      this.setState({ errormessage: "" });
      this.setState({ isLoading: true });

      configAmplify(this.state.region);

      let countryCode = document.getElementById("phoneDropDown").value;
      try {
        const newUser = await Auth.signUp({
          username: this.state.email.toLowerCase(),
          password: this.state.password,
          attributes: {
            email: this.state.email.toLowerCase(),
            phone_number: "+" + countryCode + this.getFormattedPhoneNumber(),
            "custom:firstname": this.state.firstName,
            "custom:lastname": this.state.lastName,
            "custom:countrycode": countryCode,
          },
        });
        this.setState({
          newUser,
        });
        console.log(newUser);
      } catch (e) {
        this.setState({ errormessage: e.message });
      }
      this.setState({ isLoading: false });
    }
  };

  handleConfirmationSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      var mobile_number = this.state.CountryCode + "-" + this.state.mobile;
      var password = this.state.password;
      let confirmSignUp = await Auth.confirmSignUp(
        this.state.email.toLowerCase(),
        this.state.confirmationCode
      );
      //console.log("confirmSignUp=>", confirmSignUp); return false;
      //if(confirmSignUp === "SUCCESS")
      //Analytics.record('_userauth.sign_up');
      await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
      this.props.userHasAuthenticated(true);

      /*this.props.history.push({
        pathname: "/add-signature",
        state: this.state.email,
      });*/

      const user = await this.updateLogin();
      if (user.status) {
        // window.location.href = "/dashboard"
        //window.location.href = "/add-signature";
        this.props.history.push({
          pathname: "/add-signature",
          state: this.state.email,
        });
      } else {
        await Auth.signOut();
        await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
        this.props.userHasAuthenticated(true);
        this.props.history.push({
          pathname: "/add-signature",
          state: this.state.email,
        });
        //window.location.href = "/";
      }
    } catch (e) {
      alert(e.message);
      //Analytics.record('_userauth.auth_fail');
      this.setState({ errormessage: e.message });
      this.setState({ isLoading: false });
    }
  };

  handleMobileVerificationSubmit = async (event) => {
    event?.preventDefault();
    this.setState({ isLoading: true });
    try {
      await Auth.verifyCurrentUserAttributeSubmit(
        "phone_number",
        this.state.confirmationCode
      );
      this.setState({ isLoading: false });

      // refresh the current user to update the local cache
      await Auth.currentAuthenticatedUser({ bypassCache: true });

      this.props.history.replace("/dashboard");
    } catch (error) {
      alert(event.message);
      this.setState({ errormessage: event.message, isLoading: false });
    }
  };

  handleResendConfirmationCode = async (event) => {
    event?.preventDefault();
    this.setState({ isLoading: true });
    try {
      await Auth.resendSignUp(this.state.email);
      //alert('resend succsessfully.');
      this.setState({ isLoading: false });
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  handleResendPhoneVerificationCode = async () => {
    this.setState({ isLoading: true });
    try {
      await Auth.verifyCurrentUserAttribute("phone_number");
      //alert('resend succsessfully.');
      this.setState({ isLoading: false });
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  updateLogin() {
    return this.props.postRequest("/users/update-login", {
      userEmail: this.state.email.toLowerCase(),
    });
  }

  renderConfirmationForm() {
    return (
      <div className="col-sm-12 forgot_bg">
        <div className="container p0">
          <div className="forgot_box">
            {!this.state.isVerifyingMobile && (
              <a href="/login">
                <img
                  src="images/ic_chevron_left1_24px.svg"
                  className="back_forgot_circle"
                  width="15"
                  height="23"
                  alt=""
                />
              </a>
            )}
            <div className="clear10"></div>
            <div className="forgot_circle">
              <img src="images/ic_lock1_outline_24px.svg" alt="" />
            </div>
            <h2>
              {" "}
              {this.state.isVerifyingMobile
                ? "Verify Mobile Number"
                : "Verify Account"}
            </h2>
            <div className="clear10"></div>
            If you didn't receive the PIN Code, click on "Resend",
            <br />
            otherwise fill in the code and click on "Verify".
            <div className="register_box_mid">
              <div className="clear20"></div>
              <form
                onSubmit={(e) => {
                  if (this.state.isVerifyingMobile) {
                    this.handleMobileVerificationSubmit(e);
                  } else {
                    this.handleConfirmationSubmit(e);
                  }
                }}
              >
                <div className="col-sm-12 p0">
                  <input
                    name=""
                    id="confirmationCode"
                    className="frogot_input"
                    type="text"
                    placeholder="PIN Code"
                    value={this.state.confirmationCode}
                    onChange={this.handleChange}
                  />
                  <img
                    src="images/ic_vpn_key_24px.svg"
                    width="25px"
                    height="15px"
                    className="register_icon1"
                    alt=""
                  />
                </div>
                <div className="clear10"></div>
                <div
                  className={`col-sm-12 ${
                    this.state.isVerifyingMobile ? "resend_code_wrapper" : ""
                  }`}
                >
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      this.props.history.push("/dashboard?edit-profile=true");
                    }}
                    className="resend_code"
                  >
                    Change mobile number
                  </a>
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      if (this.state.isVerifyingMobile) {
                        this.handleResendPhoneVerificationCode();
                      } else {
                        this.handleResendConfirmationCode();
                      }
                    }}
                    className="resend_code"
                  >
                    Resend code
                  </a>
                </div>
                <div className="clear10"></div>
                <LoaderButton
                  block
                  bsSize="large"
                  disabled={!this.validateConfirmationForm()}
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Verify"
                  loadingText="Verifying…"
                  className="btn_forogot"
                />
              </form>
              <div className="clear10"></div>
            </div>
          </div>
          <div className="clear10"></div>
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <div className="col-sm-12 register_bg">
            <div className="container p0">
              <div className="register_box">
                <h2> SIGN UP </h2>
                <a href="/login">
                  <img
                    src="images/ic_chevron_left1_24px.svg"
                    className="back_signup"
                    width="15"
                    height="23"
                    alt=""
                  />
                </a>
                <hr />
                <div className="register_box_mid">
                  <div className="clearfix"></div>
                  <div className="register_with_scial">
                    {" "}
                    Sign up with your social account V2
                  </div>
                  <div className="clear20"></div>
                  {!this.state.errormessage ? (
                    ""
                  ) : (
                    <div>
                      <div className="clear10"></div>
                      <div className="alert alert-danger">
                        {this.state.errormessage}
                      </div>
                    </div>
                  )}
                  {!this.state.emailValid ? (
                    ""
                  ) : (
                    <div>
                      <div className="clear10"></div>
                      <div className="alert alert-danger">
                        {this.state.emailValid}
                      </div>
                    </div>
                  )}
                  {!this.state.phoneValid ? (
                    ""
                  ) : (
                    <div>
                      <div className="clear10"></div>
                      <div className="alert alert-danger">
                        {this.state.phoneValid}
                      </div>
                    </div>
                  )}
                  {!this.state.passwordValid ? (
                    ""
                  ) : (
                    <div>
                      <div className="clear10"></div>
                      <div className="alert alert-danger">
                        {this.state.passwordValid}
                      </div>
                    </div>
                  )}
                  {!this.state.confirmPasswordValid ? (
                    ""
                  ) : (
                    <div>
                      <div className="clear10"></div>
                      <div className="alert alert-danger">
                        {this.state.confirmPasswordValid}
                      </div>
                    </div>
                  )}
                  <div className="col-xs-12 register_social_icon">
                    <button
                      className="socialbuttons"
                      onClick={() =>
                        Auth.federatedSignIn({ provider: "Facebook" })
                      }
                    >
                      <img src="images/fb.png" width="35" height="35" alt="" />
                    </button>
                    <button
                      className="socialbuttons"
                      onClick={() =>
                        Auth.federatedSignIn({ provider: "Google" })
                      }
                    >
                      <img
                        src="images/google.png"
                        width="33"
                        height="36"
                        alt=""
                      />
                    </button>
                  </div>
                  <div className="clear20"></div>
                  <div className="strike_signup">
                    <span>or</span>
                  </div>
                  <div className="clear20"></div>
                  <div className="col-sm-12 p0 text-center">
                    <div className="col-sm-12 p0">
                      <input
                        name=""
                        id="firstName"
                        className="register_input_top"
                        type="text"
                        placeholder="First Name"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                      />
                      <img
                        src="images/ic_person_outline_24px.svg"
                        width="18px"
                        height="16px"
                        className="register_icon1"
                        alt=""
                      />
                    </div>
                    <div className="col-sm-12 p0">
                      <input
                        name=""
                        id="lastName"
                        className="register_input"
                        type="text"
                        placeholder="Last Name"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                      />
                      <img
                        src="images/ic_person_outline_24px.svg"
                        width="18px"
                        height="16px"
                        className="register_icon1"
                        alt=""
                      />
                    </div>
                    <div className="col-sm-12 p0">
                      <input
                        name=""
                        id="email"
                        className="register_input"
                        type="text"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      <img
                        src="images/ic_mail_outline_24px.svg"
                        width="18px"
                        height="16px"
                        className="register_icon1"
                        alt=""
                      />
                    </div>
                    <div className="col-sm-12 p0">
                      <CountryCode />
                      <input
                        name=""
                        id="mobile"
                        className="register_input"
                        type="text"
                        placeholder="Mobile"
                        value={this.state.mobile}
                        onChange={this.handleChange}
                        style={{ width: "56%" }}
                      />

                      {/*<img src="images/ic_phone_24px.svg" width="18px" height="16px" className="register_icon1"  alt="" /> */}
                    </div>
                    <div className="col-sm-12 p0 register-input-wrapper">
                      <input
                        name=""
                        id="password"
                        className="register_input"
                        type={this.state.showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                      />
                      <img
                        src="images/ic_lock_outline_24px.svg"
                        width="18px"
                        height="16px"
                        className="register_icon1"
                        alt=""
                      />
                      {!this.state.showPassword && (
                        <img
                          src="images/signup/eye.svg"
                          className="pwd-mask-icon"
                          alt="Show password"
                          onClick={() => this.setState({ showPassword: true })}
                        />
                      )}
                      {this.state.showPassword && (
                        <img
                          src="images/signup/eye-off.svg"
                          className="pwd-mask-icon"
                          alt="Show password"
                          onClick={() => this.setState({ showPassword: false })}
                        />
                      )}
                    </div>
                    <div className="col-sm-12 p0 register-input-wrapper">
                      <input
                        name=""
                        id="confirmPassword"
                        className="register_input_bottom"
                        type={
                          this.state.showConfirmPassword ? "text" : "password"
                        }
                        placeholder="Confirm Password"
                        value={this.state.confirmPassword}
                        onChange={this.handleChange}
                      />
                      <img
                        src="images/ic_lock_outline_24px.svg"
                        width="18px"
                        height="16px"
                        className="register_icon1"
                        alt=""
                      />
                      {!this.state.showConfirmPassword && (
                        <img
                          src="images/signup/eye.svg"
                          className="pwd-mask-icon"
                          alt="Show password"
                          onClick={() =>
                            this.setState({ showConfirmPassword: true })
                          }
                        />
                      )}
                      {this.state.showConfirmPassword && (
                        <img
                          src="images/signup/eye-off.svg"
                          className="pwd-mask-icon"
                          alt="Show password"
                          onClick={() =>
                            this.setState({ showConfirmPassword: false })
                          }
                        />
                      )}
                    </div>

                    <div className="col-sm-12 p0">
                      <select
                        name="region"
                        id="region"
                        className="region-select register"
                        onChange={this.handleChange}
                      >
                        <option value="-1">Select your region</option>
                        <option value="AU">Australia</option>
                        <option value="UK">United Kingdom</option>
                      </select>
                    </div>
                  </div>
                  <div className="clear30"></div>
                  {/*<a href="" className="btn_register" data-toggle="modal" data-target="#exampleModalCenter">
										Create New Account
									</a>*/}

                  <LoaderButton
                    block
                    bsSize="large"
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Signup"
                    loadingText="Signing up…"
                    className="btn_register"
                  />
                  <div className="clear10"></div>
                  <div className="col-xs-12 p0 text-right lable_already_user">
                    Already user?
                    <a href="/login">Click here</a>
                  </div>
                  <div className="clear20"></div>
                  <div className="col-sm-12 text-center register">
                    I accept the{" "}
                    <strong>
                      <a href="/terms-conditions" target="_blank">
                        Terms of Use
                      </a>
                    </strong>{" "}
                    and{" "}
                    <strong>
                      <a href="/privacy-policy" target="_blank">
                        Privacy Policy
                      </a>
                    </strong>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
              <div className="clear40"></div>
            </div>
          </div>
          <div
            className="modal fade"
            id="exampleModalCenter"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modla_register"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header modal_header_register">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body register_suc register_popup">
                  <img
                    src="images/ic_check_circle_24px.svg"
                    width="47"
                    height="47"
                    alt=""
                  />
                  <div className="clearfix"></div>
                  <h2> You have signed up successfully</h2>
                  <div className="clearfix"></div>
                  Thank you for chossing Digital Form.
                  <div className="clear40"></div>
                  <a href="/" className="btn_ok_reg">
                    OK
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }

  render() {
    return (
      <div className="Signup">
        {this.state.newUser === null && !this.state.isInitializing
          ? this.renderForm()
          : null}
        {this.state.newUser !== null && !this.state.isInitializing
          ? this.renderConfirmationForm()
          : null}
      </div>
    );
  }
}
